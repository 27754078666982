import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
//import { Link } from '~components';
import About from './style'
import SectionTitle from './Components/SectionTitle'
//import Video from '~components/VideoModal'
import Data from '~data-yaml/about.yml'

const WhoWeAre = () => {
  const iName = Data.features.image
  const data = useStaticQuery(graphql`
  query whAboutImage($image: String ! = "image/about/") {
      allFile(filter: {relativePath: {regex: $image}}) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      dataYamlYaml(page: {eq: "about"}) {
        features {
          text
        }
      }
    }
`)

const iData = (name) => {
  let iDat = undefined
  for (let i = 0; i<data.allFile.edges.length; i++) {
    if (data.allFile.edges[i].node.base == name) {
      iDat = data.allFile.edges[i].node.childImageSharp.gatsbyImageData
    }
  }
  return iDat
}

return(
  <About backgroundColor="#f3f4f6">
  <Container>
    {/* about-us Content */}
    <Row className="align-items-center justify-content-center bg-default-3">
      <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
        <About.Image>
          <GatsbyImage className="w-100"
            image={iData(iName)} 
            alt='about'
          />
        </About.Image>
      </Col>
      <Col col="12" className="col-xxl-5 offset-xxl-1 col-lg-5 col-md-8 col-xs-10">
        <About.Content mt="40px" mtLG="0" mb="50px" mbLG="0" mlLG="30px">
              <SectionTitle 
                subTitle={Data.features.subtitle}
                title={Data.features.title}
                text={data.dataYamlYaml.features.text}
                titleProps={{mb:"30px"}}
                subTitleProps={{mb:"25px"}}
                />
        </About.Content>
      </Col>
    </Row>
    {/*/ .about-us Content */}
  </Container>
</About>

)
}
export default WhoWeAre