import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import InnerPromoShape from "~image/mixed/inner-banner-shape.png"
import Promo from './style'
import about from "~data-yaml/about.yml"
import { Link } from "~components"
 
export default function PromoSection({...rest}){
return(
<Promo backgroundColor="#5034fc">
  <Container>
    <Row className="justify-content-center text-center">
      <Col className="col-xl-8">
        <Promo.Content>
          <Promo.Title fontColor="#fff" as="h2" mb="45px">{about.Promo.cta_title}</Promo.Title>
          <Promo.Button background="#ff5722" as={Link}  to={about.Promo.cta_link} rounded={true} sizeX="180px" sizeY="56px">{about.Promo.cta_label}</Promo.Button>
        </Promo.Content>
      </Col>
    </Row>
  </Container>
  <Promo.Shape>
    <img className="w-100" src={InnerPromoShape} alt="inner shape" />
  </Promo.Shape>
</Promo>

)
}