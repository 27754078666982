import React from "react";
import { PageWrapper } from "~components/Core";
import HeroSection from "~sections/Common/Hero";
import AboutSection from '~sections/Home/About'
import WhoWeAreSection from '~sections/About/WhoWeAre'
import ContentSectionOne from '~sections/Home/ContentOne'
import TeamSection from '~sections/About/Team'
import PromoSection from '~sections/About/Promo'
import FooterSection from "~sections/Common/Footer";
import about from "~data-yaml/about.yml"
import HeroBg from "~image/heroimages/about/hero_bg.jpg"
import heroOverlay from "~image/heroimages/about/overlay.png"

const header = {
  headerClasses: "site-header site-header--menu-end dark-header site-header--sticky",
  containerFluid:false,
  darkLogo:false,
}

export default function About() {
  return (
    <PageWrapper headerConfig={header}>
        <HeroSection 
          title={about.hero.title}
          subtitle={about.hero.subtitle}
          text={about.hero.text}
          HeroBg={HeroBg}
          Overlay={heroOverlay}
        />
        <AboutSection/>
        <WhoWeAreSection/>
        <ContentSectionOne/>
        <TeamSection/>
        <PromoSection/>
        <FooterSection/>
    </PageWrapper>
  )
}
