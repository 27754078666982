import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
import { Col, Container, Row } from "react-bootstrap"
import SectionTitle from './Components/SectionTitle'
import TeamCard from "./Components/Card"
import Team from "./style"
import Data from '~data-yaml/about.yml'

export default function TeamSection({ ...rest }) {
  const data = useStaticQuery(graphql`
  query teamQuery($image: String ! = "image/home-digital-agency/team-member/") {
    allFile(filter: {relativePath: {regex: $image}}) {
      edges {
        node {
          base
          publicURL
        }
      }
    }
  }
`)

  const mTeam = () => {

    var teama = []
    var teamaMember = {}
    var avatar = "nope"
    Data.team.map((tm, i) => {
      teamaMember.userName = tm.userName
      teamaMember.userPosition = tm.userPosition
      teamaMember.userLinkedIn = tm.userLinkedIn
      data.allFile.edges.map(im => {
        if (im.node.base == tm.image) {
          avatar = im.node.publicURL
        }
      })
      teamaMember.image = avatar
      teama.push(teamaMember)
      teamaMember = {}
    }
    )
    return teama
  }
  const arrTeama = mTeam()

  const items = arrTeama.map((mb, i) => {
    return <TeamCard image={mb.image} userName={mb.userName} userPosition={mb.userPosition} userLinkedIn={mb.userLinkedIn} key={"member-" + i} />
  })

  const elSlider = React.useRef();

  const sliderConfig = {
    centerMode: true,
    centerPadding: "180px",
    slidesToShow: 3,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: "40px",
          slidesToShow: 1,
          dots: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: "100px",
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  }
  return (
    <Team {...rest} backgroundColor="#f3f4f6">
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xxl-6 col-md-8 col-xs-10">
            <Team.Box className="text-center">
              <SectionTitle
                subTitle="Our Team"
                title="Meet the minds shaping <br class='d-none d-xs-block'>our business"
                titleProps={{ mb: "33px" }}
                subTitleProps={{ mb: "20px" }}
              />
            </Team.Box>
          </Col>

        </Row>

      </Container>
      <Container fluid={true}>
        <Team.SliderWrapper>

          <Slider
            ref={elSlider}
            {...sliderConfig}
          >

            {items}
            
          </Slider>
        </Team.SliderWrapper>
      </Container>
    </Team>
  )
}
